import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import styles from './LeadersPage.module.scss'
import TeamContainer from '../IndexPage/TeamContainer'
import { parseImage, parseAnchor } from '@utils/contentful'
import { TransitionState } from 'gatsby-plugin-transition-link'

import Layout from '@components/Layout'

const cx = classnames.bind(styles)

const LeadersPage = ({ data }) => {
  const { contentfulHomeTeam } = data
  const [, setOpacityStyle] = useState()
  const handleOpacityChange = style => {
    setOpacityStyle(style)
  }

  let logos = populateLogos(contentfulHomeTeam)

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <Layout
            className={transitionStatus}
            onOpacityChange={handleOpacityChange}
            seo={contentfulHomeTeam.header}
            smoothScroll={true}
          >
            <TeamContainer
              members={contentfulHomeTeam.members.map(item => ({
                ...item,
                image: item.image && parseImage(item.image),
              }))}
              header={contentfulHomeTeam.header}
              subHeader={contentfulHomeTeam.subHeader}
              anchor={parseAnchor(contentfulHomeTeam.anchor.url)}
              logos={logos}
            />
            <div className={cx('useless')}></div> {/*this div is just there so eslint stays happy*/}
          </Layout>
        )
      }}
    </TransitionState>
  )
}

function populateLogos(contentfulHomeTeam) {
  let logos = []
  logos.push(contentfulHomeTeam.logoA)
  logos.push(contentfulHomeTeam.logoB)
  logos.push(contentfulHomeTeam.logoC)
  logos.push(contentfulHomeTeam.logoD)
  logos.push(contentfulHomeTeam.logoE)
  logos.push(contentfulHomeTeam.logoF)
  logos.push(contentfulHomeTeam.logoG)
  return logos
}

LeadersPage.propTypes = {
  data: PropTypes.object,
}

export default LeadersPage
