import LeadersPage from '@components/LeadersPage'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    contentfulHomeTeam {
      anchor {
        url
      }
      header
      subHeader {
        subHeader
      }
      members {
        image {
          description
          file {
            url
          }
        }
        name
        title
        company
        education {
          education
        }
        fullTitle
        experience {
          experience
        }
        displaySection
      }
      logoA {
        description
        file {
          url
        }
      }
      logoB {
        description
        file {
          url
        }
      }
      logoC {
        description
        file {
          url
        }
      }
      logoD {
        description
        file {
          url
        }
      }
      logoE {
        description
        file {
          url
        }
      }
      logoF {
        description
        file {
          url
        }
      }
      logoG {
        description
        file {
          url
        }
      }
    }
  }
`

export default LeadersPage
